@font-face {
	font-family: "limburgdispatcher";
	src: url("/f62ef6fd6202820bb203-limburgdispatcher.eot?#iefix") format("embedded-opentype"),
url("/f62ef6fd6202820bb203-limburgdispatcher.woff") format("woff"),
url("/f62ef6fd6202820bb203-limburgdispatcher.woff2") format("woff2"),
url("/f62ef6fd6202820bb203-limburgdispatcher.ttf") format("truetype"),
url("/f62ef6fd6202820bb203-limburgdispatcher.svg#limburgdispatcher") format("svg");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: limburgdispatcher !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon.arrow-left:before {
	content: "\f101";
}
.icon.arrow-right:before {
	content: "\f102";
}
.icon.back-arrow:before {
	content: "\f103";
}
.icon.bell:before {
	content: "\f104";
}
.icon.calendar-alt:before {
	content: "\f105";
}
.icon.calendar:before {
	content: "\f106";
}
.icon.checkmark:before {
	content: "\f107";
}
.icon.cross:before {
	content: "\f108";
}
.icon.duplicate:before {
	content: "\f109";
}
.icon.empty-box:before {
	content: "\f10a";
}
.icon.error:before {
	content: "\f10b";
}
.icon.forward-arrow:before {
	content: "\f10c";
}
.icon.house:before {
	content: "\f10d";
}
.icon.incident:before {
	content: "\f10e";
}
.icon.info:before {
	content: "\f10f";
}
.icon.list:before {
	content: "\f110";
}
.icon.location:before {
	content: "\f111";
}
.icon.mail:before {
	content: "\f112";
}
.icon.marker:before {
	content: "\f113";
}
.icon.mobile:before {
	content: "\f114";
}
.icon.more:before {
	content: "\f115";
}
.icon.pencil:before {
	content: "\f116";
}
.icon.people:before {
	content: "\f117";
}
.icon.power:before {
	content: "\f118";
}
.icon.qr:before {
	content: "\f119";
}
.icon.reset:before {
	content: "\f11a";
}
.icon.save:before {
	content: "\f11b";
}
.icon.search:before {
	content: "\f11c";
}
.icon.tag:before {
	content: "\f11d";
}
.icon.trash:before {
	content: "\f11e";
}
.icon.truck:before {
	content: "\f11f";
}
.icon.user:before {
	content: "\f120";
}
